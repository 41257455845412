import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomePageBody from './pages/HomePageBody';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <Router>
      <div className="d-flex flex-column w-100min-vh-100">
        <NavBar />
        <main className="flex-grow-1 mx-0 px-0"> {/* Added pt-5 and mt-4 */}
          <div className="container-fluid mx-0 px-0">
            <HomePageBody />
          </div>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
