import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CarouselWithBlur.css'; // Custom CSS for the blur effect

const images = [
    'https://images.pexels.com/photos/26125152/pexels-photo-26125152/free-photo-of-the-milky-way-over-the-lake-at-night.jpeg', 
    'https://images.pexels.com/photos/28397252/pexels-photo-28397252/free-photo-of-dazzling-star-trails-over-dark-forest.jpeg'
  ];

const HomePageBody = () => {
  return (
    <>
      <div className="carousel-container">
        <div className="carousel-blurred-bg">
          <img src={images[0]} alt="Blurred background" className="blur-image" />
        </div>
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            {images.map((img, index) => (
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                <div className="carousel-image-container">
                  <img src={img} className="d-block w-100 h-100 carousel-image" alt={`Slide ${index}`} />
                </div>
              </div>
            ))}
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default HomePageBody;