import Social from "./Social"
import 'font-awesome/css/font-awesome.min.css';
import FooterItems from "./FooterItems"
const Footer = () => {
    const textColor = "text-dark"
    return (
        <div>
            <footer className={`text-center min-height: 100vh; ${textColor}`} style={{backgroundColor: "#ff7730"}}>
                <div className="container">
                    <section className="mt-0">
                        <div className="row text-center d-flex justify-content-center pt-5">
                            <FooterItems textColor={textColor} textShow="About us" />
                            <FooterItems textColor={textColor} textShow="Contact" />
                        </div>
                    </section>

                    <hr className="my-5" />
                    <section className="text-center mb-5">
                        <Social textColor={textColor} icon="fa-facebook-f" />
                        <Social textColor={textColor} icon="fa-twitter" />
                        <Social textColor={textColor} icon="fa-google" />
                        <Social textColor={textColor} icon="fa-linkedin" />
                        <Social textColor={textColor} icon="fa-github" />
                    </section>
                </div>
                <div
                    className="text-center p-3"
                    style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}
                >
                    © 2024 Copyright:
                    <a className="text-white" href="https://muskanprajapati.com/">
                        muskanprajapati.com
                    </a>
                </div>
            </footer>
        </div>
    )
}

export default Footer;