const FooterItems = ({textColor, textShow}) => {
    return (
        <div class="col-md-2">
            <h6 className="text" style={{fontWeight: "bold" }}>
                <a href="#!" className={textColor}>{textShow}</a>
            </h6>
        </div>
    )
}

export default FooterItems
