import { Link } from 'react-router-dom';
import React from 'react';
import logo from '../assets/muskan-logo-zip-file (1)/png/logo-no-background.png';

const NavBar = () => {
    return (
            <nav className="navbar navbar-expand-lg fixed-top" style={{backgroundColor: '#ff7730'}}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <div className='m-3'>
                            <img src={logo} alt="Muskan Logo"  className='img-fluid' style={{width: 'auto', height: '25px'}}/>
                        </div>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button> 
                    <div className='col-3'>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <a className="nav-link text-dark " style={{fontWeight: 'bold', fontSize: '20px'}} href="#">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-dark mx-3" style={{fontWeight: 'bold', fontSize: '20px'}} href="#">Blog</a>
                            </li>
                            
                            
                            </ul>
                            
                            {/* <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> */}
                            {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
                        </div>
                    </div>
            </div>
        </nav>
    )
}

export default NavBar;
